import React, { useState, useEffect } from "react";
import { useGetJobUL } from "../api/queries/workflowjobs";
import Loading from "../components/loading";
import Error from "../components/error";
import { List, ListItem } from "../components/list";
import GroupPreview from "../components/group-preview";
import ShapePreview from "../components/shape-preview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { useWorkflowJobUpdateProp } from "../api/mutations/workflowjobs";
import BoardField from "../components/board/board-field";

export default function JobWorkflowUlPanel({jobId}) {
    const ulQuery = useGetJobUL(jobId);
    const job = ulQuery.data?.job;
    const groups = ulQuery.data?.groups;
    const powersets = ulQuery.data?.powersets;

    const updateJobUl = useWorkflowJobUpdateProp(jobId, "ul");

    return (
        <div className="">
            {ulQuery.isLoading ?
                <Loading />
            : ulQuery.isError ?
                <Error message="Error loading UL data" />
            :
                <div>
                    <div className="space-y-2">
                        <List>
                            <ListItem className="grid grid-cols-[150px,1fr] items-center">
                                <div className="text-sm font-bold">
                                    Entire Job
                                </div>
                                <div>
                                    <BoardField
                                        type="text"
                                        label="UL"
                                        value={job.ul || ""}
                                        saveMutation={updateJobUl}
                                        // onSave={(value) => {
                                        //     return {
                                        //         jobGroupId: group.id, 
                                        //         partId: part.id, 
                                        //         prop: "quantity", 
                                        //         value                                                                        
                                        //     }
                                        // }}
                                    />                                  
                                </div>
                            </ListItem>
                        </List>

                        <List>
                            {powersets.map(powerset => (
                                <ListItem key={powerset.id} className="grid grid-cols-[150px,auto,1fr] items-center">
                                    <div className="text-sm">
                                        <strong>{powerset.name}</strong>
                                        <div className="text-xs flex items-center gap-1">
                                            <FontAwesomeIcon icon={faPlug} />
                                            <div>
                                                {powerset.part}
                                            </div>                                            
                                        </div>
                                        {powerset.type == "Attached" ?
                                            <div>
                                                Attached to {powerset.parent || "?"}
                                            </div>
                                        : powerset.type == "Separated" ?
                                            <div>
                                                Separated from {powerset.parent || "?"}
                                            </div>
                                        :
                                            <div>
                                                {powerset.type}
                                            </div>
                                        }                                        
                                    </div>

                                    <div>
                                        {powerset.ul ?
                                            <div className="bg-gray-200 border rounded-sm p-1 text-sm">
                                                {powerset.ul}
                                            </div>
                                        :
                                            <div className="text-gray-500">No UL Sticker Found</div>
                                        }
                                    </div>  
                                    <div></div>                        
                                </ListItem>
                            ))}
                        </List>

                        <List>
                            {groups.map(group => (
                                <ListItem key={group.id} className="grid grid-cols-[150px,1fr] items-center">
                                    <div className="text-xs">
                                        <GroupPreview group={group} solid={true} width={100} height={50} />
                                        <div>
                                            {group.product}
                                        </div>
                                        <div>
                                            Size {group.size}
                                        </div>
                                        {group.style_type == "letters" && 
                                            <div>
                                                {group.style}
                                            </div>
                                        }
                                    </div>

                                    <div className="space-y-1">
                                        <List direction="horizontal" className="flex-wrap">
                                            {group.shapes.filter(s => s.ul).map(shape => (
                                                <ListItem 
                                                    key={shape.signshape_id} 
                                                    size="sm"
                                                    className="flex-column !justify-center gap-2"
                                                >
                                                    <ShapePreview path={shape.path} width={25} height={25} />
                                                    <div className="bg-gray-200 border rounded-sm p-1 text-sm">
                                                        {shape.ul}
                                                    </div>
                                                </ListItem>
                                            ))}

                                            {group.shapes.filter(s => s.ul).length == 0 && 
                                                <div className="text-gray-500">No UL Stickers Found</div>
                                            }
                                        </List> 
                                    </div>                          
                                </ListItem>
                            ))}
                        </List>
                    </div>

                    {/* <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Properties</th>
                                <th>UL</th>
                                <th>Date Used</th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.map(item => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        {item.type}
                                    </td>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                    {item.type == "Shape" && 
                                        <>
                                        {item.face && 
                                            <ColorBox 
                                                color={{name: item.face.color_name, hex_day: item.face.color_hex}} 
                                                size="small" 
                                            />
                                        }
                                        {item.style}, Size: {item.size}
                                        </>
                                    }
                                    {item.type == "Powerset" && 
                                        <>{item.size} {item.style}</>
                                    }
                                    </td>
                                    <td>
                                    <InputGroup size="sm">
                                        <FormControl
                                            defaultValue={item.ul}
                                            onChange={(e) => { 
                                                e.persist();

                                                setItems(produce(draft => {
                                                draft[draft.findIndex(i => i.type==item.type && i.id==item.id)].ul = e.target.value;
                                                }));
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <Button disabled={!item.ul || item.ul.length == 0} onClick={() => onUlDown(item)} variant="outline-secondary">
                                                <FontAwesomeIcon icon={faArrowAltCircleDown} />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    </td>
                                    <td>
                                        {formatDate(item.date)}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>                        
                    </table> */}
                </div>
            }
        </div>
    )
}