import storage from "../settings/storage";
import { TaskStatuses } from "./data";

function findLastValidTask(task, tasks, depth=0) {
    // if (task.name == "Bend Joiner Plates & Install Riv Nuts") {
        console.log(">", "findLastValidTask", task.name, depth);
    // }
      
    if (depth > 20)
      return null;
  
    for (const node of task.nodes) {
      //console.log(">>", "checking Node", node.name, node.dependent_value);
  
      const nodeTask = tasks.find(t => t.workflowtask_id == node.dependent_workflowtask_id);
  
      if (nodeTask) {
        //console.log(">>>", nodeTask.name, nodeTask.status);
  
        if (task.name == "CNC Joiner Plates") {
            console.log("task", task)
            console.log("node", node)
            console.log("nodeTask", nodeTask)
        }

        if (nodeTask.check_ind == 1) {
            if (node.dependent_value == "Yes" && (nodeTask.status_id == 8 || nodeTask.status_id == 15)) {
                if (task.name == "CNC Joiner Plates")console.log("### check is true")
                return nodeTask;
            }
            else if (node.dependent_value == "No" && nodeTask.status_id == 88) {
                if (task.name == "CNC Joiner Plates")console.log("### check is false")
                return nodeTask;
            }
            else {
                if (task.name == "CNC Joiner Plates")console.log("### check is invalid")
                return null;
            }
        }
        else if (nodeTask.status_id == TaskStatuses.pending.id || nodeTask.status_id == TaskStatuses.active.id || nodeTask.status_id == TaskStatuses.completed.id)
          return nodeTask;
        else if (nodeTask.nodes && nodeTask.nodes.length > 0);
          return findLastValidTask(nodeTask, tasks, depth+1);
      }
    //   else {
    //     console.log(">>?", "node NOT found", node.dependent_workflowtask_id);
    //   }
    }
  }

function simplifyWorkflow(workflow) {
    const tasks = [];

    console.log("*** simplifyWorkflow");

    for (let task of workflow.tasks) {
        let valid = true;

        if (task.check_ind == 0 && (task.status_id==TaskStatuses.skipped.id || task.status_id==TaskStatuses.invalid.id)) {
            //console.log(task.name, "-", "non check, skipped or invalid");
            valid = false;
        }

        // its a valid task, but need to process it nodes to make them valid
        if (valid) {
            if (task.nodes.length) {
                let validNodes = [];

                if (task.name == "Assemble Cabinet") {
                    console.log(task.name)
                }

                for (const node of task.nodes) {
                    // let all not invalid checks through
                    if (node.task.check_ind == 1 && node.task.status_id != TaskStatuses.invalid.id) {
                        validNodes.push(node)
                    }
                    else if (node.task.status_id == TaskStatuses.skipped.id || node.task.status_id == TaskStatuses.invalid.id) {
                        const last = findLastValidTask(node.task, workflow.tasks);

                        if (last) {
                            validNodes.push({
                                ...node,
                                dependent_workflowtask_id: last.job_workflow_id,
                                task: last
                            })
                        }
                    }
                    else validNodes.push(node);
                }

                // make a copy
                tasks.push({
                    ...task,
                    nodes: validNodes
                });

                if (task.name == "Assemble Cabinet") {
                    console.log(task.name, "-", validNodes.map(n => n.task.name))
                }
            }
            else {
                tasks.push(task)

                //console.log(task.name, "-", "no nodes");
            };
        }
        // else {
        //     console.log("Skipping", task.name)
        // }
    }
    
    // prune invalid nodes
    for (let task of tasks.filter(t => t.nodes.length)) {
        //console.log("**prune nodes", task.name, task.job_workflow_id);

        for (var i=task.nodes.length-1; i>=0; i--) {
            let node = task.nodes[i];
            let nodeTask = tasks.find(t => t.job_workflow_id == node.task.job_workflow_id);

            if (task.name == "Assemble Cabinet") {
                console.log("**->", node.task.name, node.task.job_workflow_id)
            }

            if (!nodeTask) {
                console.log("!prune node", task.name, "-", node.task.name)
                task.nodes.splice(i, 1);
            }
        }
    }
    // prune tasks that nothing is dependent on
    for (var i=tasks.length-1; i>=0; i--) {
        let task = tasks[i];
        let nodeCount = 0;
        
        if (task.name != "Complete") { // special case 
            //console.log("**prune check", task.name);
            for (const task2 of tasks) {
                //console.log("***", task2.name, " -> ", task2.nodes.map(n => n.task.name).join(","))
                nodeCount = nodeCount + task2.nodes.filter(n => n.task.job_workflow_id == task.job_workflow_id).length;
            }

            //console.log("**nodeCount", nodeCount);

            if (nodeCount == 0) {
                console.log("!!prune task", task.name)
                tasks.splice(i, 1);
            }
        }
    }    

    return tasks;
}

export function getMermaidChartData(workflow, selectedTaskId=null, options={}) {
    console.log("getMermaidChartData")
    if (!workflow || !workflow.tasks) return "";

    const tasks = options.simplfy ? simplifyWorkflow(workflow) : workflow.tasks;
    let markdown = `flowchart LR\n`

    console.log(tasks);

    //
    // define all of the task items
    //let group = "";
    for (const task of tasks) {
        // if (group.length && task.workflowgroup != group) {
        //     markdown += `\tend\n`;
        // }
        // else if (task.workflowgroup && task.workflowgroup.length && task.workflowgroup != group) {
        //     markdown += `\tsubgraph ${task.workflowgroup}\n`;
        // }
        
        if (task.type == "check")
            markdown += `\ttask${task.workflowtask_id}{${task.name}}\n`;
        else if (task.type == "delay" && task.delay_type == "middle-of-day")
            markdown += `\ttask${task.workflowtask_id}((Half Day Delay))\n`;
        else if (task.type == "delay" && task.delay_type == "end-of-day")
            markdown += `\ttask${task.workflowtask_id}((Tomorrow Delay))\n`;
        else if (task.type == "delay")
            markdown += `\ttask${task.workflowtask_id}((${task.delay_time} Hours))\n`;
        else if (task.type == "merge")
            markdown += `\ttask${task.workflowtask_id}>${task.name}]\n`;
        else
            markdown += `\ttask${task.workflowtask_id}(${task.name.replace(/\"/g,'&quot;')}${options.showTime ? " \n"+(task.production_estimate || 0)+" mins":""}${options.showDay && task.status_id != 88 && task.status_id != 89 ? " \nDay "+(task.production_day || 0):""})\n`;

        // group = (task.workflowgroup || "");
    }
    // if (group.length) {
    //     markdown += `\tend\n`;
    // }

    //
    // defined the task relationships
    for (const task of tasks) {
        if (task.nodes) {
            for (const node of task.nodes) {
                const lineText = node.dependent_value && node.dependent_value.length ? `|${node.dependent_value}|` : ``;
                let lineType = "-";

                // if (lineText.length && task.dependent_check != "or") {
                //     const dependentTask = workflow.tasks.find(t => t.workflowtask_id == node.dependent_workflowtask_id);

                //     if (dependentTask && node.dependent_value == "Yes" && (dependentTask.status_id == 8 || dependentTask.status_id == 15))
                //         lineType = "==";
                //     else if (dependentTask && node.dependent_value == "No" && dependentTask.status_id == 88)
                //         lineType = "==";
                // }

                markdown += `\ttask${node.dependent_workflowtask_id}${lineType}${task.dependent_check == "or"?".":""}${lineType}>${lineText}task${node.workflowtask_id}\n`
            } 
        }
    }
  
    // assign all classes
    for (const task of tasks) {
        let className = (task.status || "default") + "Class";
        
        if (selectedTaskId==task.workflowtask_id) 
            className="selectedClass"
        else if (
            task.type == "task" && (
                (options.showTime && (!task.production_estimate || task.production_estimate <= 0)) ||
                (options.showDay && task.status != "skipped" && task.status != "invalid" && (!task.target_day || task.target_day <= 0))
            )
        ) {
            className="problemClass"
        }

        markdown += `\tclass task${task.workflowtask_id} ${className}\n`
    }

    // line/link styles
    let linkIndex = 0;
    for (const task of tasks) {
        if (task.nodes) {
            for (const node of task.nodes) {
                const lineText = node.dependent_value && node.dependent_value.length ? `|${node.dependent_value}|` : ``;
                let strokeWidth = 2;
                let strokeColor = "black";

                if (lineText.length && task.dependent_check != "or") {
                    const dependentTask = workflow.tasks.find(t => t.workflowtask_id == node.dependent_workflowtask_id);

                    if (dependentTask && node.dependent_value == "Yes" && (dependentTask.status_id == 8 || dependentTask.status_id == 15)) {
                        strokeWidth = 4;
                        strokeColor = "green";
                    }
                    else if (dependentTask && node.dependent_value == "No" && dependentTask.status_id == 88) {
                        strokeWidth = 4;
                        strokeColor = "green";
                    }
                }

                markdown += `\tlinkStyle ${linkIndex} stroke-width:${strokeWidth}px,fill:none,stroke:${strokeColor};\n`

                linkIndex++;
            } 
        }
    }

    // make them all clickable
    for (const task of tasks) {
      markdown += `\tclick task${task.workflowtask_id} call callback(${task.workflowtask_id}); \n`
    }

    markdown += 
    "\tclassDef defaultClass fill:white,stroke:#999,color:black\n" +
    "\tclassDef validationClass fill:white,stroke:red,color:red\n" +
    "\tclassDef warningClass fill:white,stroke:orange,color:orange\n" +
    "\tclassDef pendingClass fill:white,stroke:#999,color:black\n" +
    "\tclassDef skippedClass fill:#eee,stroke:#ccc,color:#999\n" +
    "\tclassDef invalidClass fill:#666,stroke:#ccc,color:white,text-decoration:line-through\n" +
    "\tclassDef activeClass fill:white,stroke:#28a745,color:#28a745,stroke-width:2px\n" +
    "\tclassDef problemClass fill:red,stroke:red,color:white\n" +
    "\tclassDef pausedClass fill:#ffc107,stroke:#ffc107,color:white\n" +    
    "\tclassDef inprogressClass fill:#007bff,stroke:#007bff,color:white\n" +
    "\tclassDef completedClass fill:#28a745,stroke:#28a745,color:white\n" 
    ;

    //console.log(markdown.split("\n"))
    return markdown;
}