import React, { useState } from "react";
import clsx from "clsx";

import Loading from "../../components/loading"
import JobTaskDrawer from "../../drawers/drawer-jobworkflowtask";
import JobTasksDrawer from "../../drawers/drawer-jobworkflowtasks";

import { useGetWorkflowJobsScheduleData } from "../../api/queries/workflowjobs";
import { format, parseISO } from "date-fns";
import TimeBadge from "../../components/badge-time";

function JobsSchedule(props) {
    const [selectedJob, setSelectedJob] = useState(null);
    const [selected, setSelected] = useState(null);

    const workflowJobsScheduleQuery = useGetWorkflowJobsScheduleData();
    const schedule = workflowJobsScheduleQuery.data;

    return (
        <>
            {
                workflowJobsScheduleQuery.isLoading 
            ?
                <Loading />
            :    
                <>
                    <div className="flex gap-1 h-full">
                        {schedule.map((day, i) => {
                            return (
                                <div key={i} className={clsx(
                                    "flex flex-col border rounded",
                                    day.weekend && "bg-gray-200"
                                )}>
                                    {day.weekend ? 
                                        <div>&nbsp;</div>
                                    :
                                        <>
                                            <div className="bg-gray-200 px-2 py-1 text-center whitespace-nowrap sticky top-0">
                                                <div className="text-xs">
                                                    {format(parseISO(day.date), "EEE")}
                                                </div>
                                                {format(parseISO(day.date), "MMM do")}
                                            </div>
                                            <div className="text-xs space-y-1 p-1 overflow-y-auto">
                                                {day.skills.map(skill => (
                                                    <button 
                                                        className="p-1 text-center border rounded w-full hover:bg-gray-100"
                                                        onClick={() => setSelected({mode:"Tasks", day, skill})}
                                                    >
                                                        <div className="whitespace-nowrap">{skill.name}</div>
                                                        <div className="flex items-center justify-center gap-2">
                                                            <span className="text-lg">{skill.tasks}</span>
                                                            <TimeBadge minutes={skill.time} />
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>                                            
                                        </>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    {(!workflowJobsScheduleQuery.isError && schedule && schedule.length == 0) && 
                        <div className="text-gray-500 text-xl">
                            No Job Tasks Found
                        </div>
                    }
                    {workflowJobsScheduleQuery.isError && 
                        <div className="text-red-500 text-xl">
                            Error Loading Job Tasks
                        </div>                
                    }
                </>                
            }

            { (selected && selected.mode == "Task") && 
                <JobTaskDrawer 
                    jobId={selected.job.id}
                    jobWorkflowId={selected.task.job_workflow_id} 
                    show={true}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { (selected && selected.mode == "Tasks") && 
                <JobTasksDrawer 
                    filters={{
                        statuses: ['pending', 'active', 'inprogress', 'paused', 'problem'],
                        role_id: selected.skill.role_id,
                        date: selected.day.label
                    }}
                    skill={selected.skill}
                    show={true}
                    onTask={(job, task) => setSelected({mode: "Task", job, task})}
                    onHide={ () => setSelected(null) } 
                /> 
            }                                    
        </>
    );
}

export default JobsSchedule;