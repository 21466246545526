import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSpinner } from "@fortawesome/free-solid-svg-icons";

import DropdownMenu from "./dropdownmenu";
import Badge from "./badge";
import Tooltip from "./tooltip";

export function ListItem({
    count=null,
    countVariant,
    countTooltip,
    onCountClick,
    size,
    disabled,
    className,
    menu,
    actions,
    busy,
    onClick,
    onMenuItem,
    children
}) {
    return (
        <li>
            <div 
                className={clsx(
                    "bg-white border border-gray-300 flex items-center justify-between w-full",
                    onClick && !disabled && "cursor-pointer hover:!bg-gray-100",
                    size == "sm" ? "p-1" : "p-3",
                    disabled && "!bg-gray-100",                    
                    className,
                )}
                onClick={onClick}
            >
                {children}
                <div className="flex items-center gap-1">
                    {busy && 
                        <FontAwesomeIcon icon={faSpinner} spin />
                    }
                    {(count != null && count > 0 && countTooltip) ?
                        <Tooltip
                            align="right"
                            content={<Tooltip>{countTooltip}</Tooltip>}
                        >
                            <Badge variant={countVariant || "secondary"} value={count} onClick={onCountClick} />
                        </Tooltip>
                    : (count != null && count > 0) ?
                        <Badge variant={countVariant || "secondary"} value={count} onClick={onCountClick} />
                    :
                        null
                    }
                    {(menu && menu.length > 0) && 
                        <DropdownMenu
                            icon={faBars}
                            disabled={disabled || busy}
                            menu={menu}
                            onMenuItem={onMenuItem}
                        />
                    }
                    {actions}
                </div>
            </div>          
        </li>
    )
}

export function List({
    direction, // horizontal, vertical
    className,
    children
}) {
  return (
    <ul className={clsx(
        direction == "vertical" ? "h-full": "w-full",
        direction == "horizontal" && "flex",
        direction == "horizontal" ? "[&>li:first-child>div]:rounded-l" : "[&>li:first-child>div]:rounded-t",
        direction == "horizontal" ? "[&>li:last-child>div]:rounded-r" : "[&>li:last-child>div]:rounded-b",
        // direction == "horizontal" ? "[&>li:last-child>div]:border-l-0" : "[&>li:last-child>div]:border-b-0",
        className
    )}>
        {children}
    </ul>
  )
}
