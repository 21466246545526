import React, { useState, useEffect, useRef, useContext, memo } from "react";
import { faCheckCircle, faExclamationCircle, faPencilAlt, faPlus, faRedo, faSpinner } from "@fortawesome/free-solid-svg-icons";
import mermaid from "mermaid";
import clsx from "clsx";

import JobWorkflowTaskDrawer from "../drawers/drawer-jobworkflowtask";
import Alert from "../components/alert";
import Field from "../components/fields/field";
import Panel from "./panel";
import ButtonMutate from "../components/buttons/button-mutate";

import { PusherContext } from "../common/services/pusher";
import { getMermaidChartData } from "../helpers/workflow";
import { dimensions } from "../settings/settings";
import { useGetJobWorkflowData } from "../api/queries/workflowjobs";
import { CardLayoutPanel } from "../components/cardlayout";
import { useWorkflowJobCompleteTask, useWorkflowJobResyncWorkflow, useWorkflowJobUpdateTaskProp } from "../api/mutations/workflowjobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskStatuses } from "../helpers/data";
import Switch from "../components/switch";
import { Accordion, AccordionItem } from "../components/accordion";
import { List, ListItem } from "../components/list";
import { format, parseISO } from "date-fns";

mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
    securityLevel: 'loose',
    fontFamily: 'monospace',
    htmlLabels: true
});

// const Mermaid = memo(function Mermaid({chart}) {
//     const chartRef = useRef();
    
//     useEffect(
//       () => {
//         if (chart && chart.length) {
//           chartRef.current.removeAttribute('data-processed');
//           mermaid.contentLoaded();
//         }
//       }, 
//       [chart]
//     );

//     console.log("mermaid rerender")
//     return (
//         chart ? 
//           <pre ref={chartRef} className="mermaid" style={{color:"white"}}>{chart}</pre>
//         :
//           <p>No data found</p>        
//     )
// });

function Mermaid({chart}) {
  const chartRef = useRef();

  const renderDiagram = async (chart) => {
    console.log("mermaid chart changed")

    const containerRef = document.getElementById("card-layout-panel");

    const scrollLeft = containerRef ? containerRef.scrollLeft : 0;
    const scrollTop = containerRef ? containerRef.scrollTop : 0;

    // chartRef.current.removeAttribute('data-processed');
    // mermaid.contentLoaded();
    // mermaid.init(undefined, $("#mermaid"));

    // Example of using the render function
    const drawDiagram = async function () {
      const graphDefinition = chart;
      const { svg, bindFunctions } = await mermaid.render('graphDiv', graphDefinition);
      chartRef.current.innerHTML = svg;

      if (bindFunctions) {
        bindFunctions(chartRef.current);
      }      

      if (containerRef) {
        containerRef.scrollTo(scrollLeft, scrollTop);
      }
    };
  
    await drawDiagram();
  }

  useEffect(
    () => {
      if (chart && chart.length) {
        renderDiagram(chart);
      }
    },
    [chart]
  );

  return (
    chart ?
      <pre ref={chartRef} className="mermaid" style={{color:"white"}}></pre>
    :
      <p>No data found</p>
  )
}

function TaskItem({jobId, task, onTask, showTime}) {
  const completeWorkflowTask = useWorkflowJobCompleteTask(jobId);

  return (
    <AccordionItem 
      title={
        <div className="flex items-center justify-between gap-2 w-full">
          {task.name}
          {showTime && 
            <div className="text-sm p-1 rounded bg-white text-gray-500 border">
              {task.type=="delay" ? task.delay_time + " hours" : task.production_estimate + " minutes"}
            </div>
          }
        </div>      
      }
      size="sm"    
      disabled={task.type=="delay"}  
      headerClassName={clsx(
        task.status == "active" && "!border-green-500 !text-green-500",
        task.status == "completed" && "!bg-green-500 !text-white",
        task.status == "paused" && "!bg-yellow-500",
        task.status == "problem" && "!bg-red-500 !text-white",
        task.status == "skipped" && "!bg-gray-200 !text-gray-400",
        task.status == "invalid" && "!bg-gray-400 line-through !text-white",
      )}
      menu={task.type=="delay" ? null : [
        {name: "complete", icon: faCheckCircle, caption: "Complete Task", hidden: task.status == "completed"},
        {name: "edit", icon: faPencilAlt, caption: "Edit Task"}
      ]}
      onMenuItem={(item) => {
        if (item.name == "edit")
          onTask(task);
        else if (item.name == "complete") 
          completeWorkflowTask.mutate(task.job_workflow_id);
      }}
      busy={completeWorkflowTask.isLoading && completeWorkflowTask.variables == task.job_workflow_id}
    >
      <div><span className="font-bold">Production Time Formula:</span> {task.production_estimateformula}</div>
      <div><span className="font-bold">Production Calculation:</span> {task.production_estimatecalc}</div>
      <div><span className="font-bold">Production Target Day:</span> {task.target_day}</div>
      {task.status == "completed" && <div><span className="font-bold">Production Actual Day:</span> {task.actual_day}</div>}
    </AccordionItem>    
  )
}
export default function JobWorkflowPanel({jobId, showTasksList=true, overflow=false}) {
    const containerRef = useRef();

    const pusher = useContext(PusherContext);
    const [diagram, setDiagram] = useState(null);
  
    const [task, setTask] = useState(null);  
    const [taskMenu, setTaskMenu] = useState(null);  
      
    const [showTime, setShowTime] = useState(false);
    const [showDay, setShowDay] = useState(false);
    const [showSimplified, setShowSimplified] = useState(false);

    const workflowQuery = useGetJobWorkflowData(jobId);
    const workflow = workflowQuery.data;

    const resyncWorkflow = useWorkflowJobResyncWorkflow(jobId);
    const updateWorkflowTaskStatus = useWorkflowJobUpdateTaskProp(jobId, taskMenu ? taskMenu.task.job_workflow_id : null, "status_id", {
      onError: () => window.alert("Error updating task")
    })

    useEffect(
      () => {
        window.callback = e => {
          console.log("callback", e)
          
          const selected = workflow.tasks.find(t => t.workflowtask_id == parseInt(e));

          console.log("task", selected)
          if (selected) {
            const node = document.querySelector(`g[data-id='task${e}']`)
    
            console.log({node})

            console.log(node.getBoundingClientRect().top);
            console.log(node.offsetTop);
            console.log(containerRef.current.offsetTop);
            console.log(document.getElementById("card-layout-panel")?.scrollTop);            
            console.log(node.getBoundingClientRect().top - containerRef.current.offsetTop + (document.getElementById("card-layout-panel")?.scrollTop||0));


            setTaskMenu({node, task: selected});
          }
          else window.alert("Unable to find the selected task???")
        }
      }, 
      [workflow, task]
    );

    useEffect(
      () => {
        console.log("generating mermaid chart data")
        setDiagram(getMermaidChartData(workflow, null, { showTime, showDay, simplfy: showSimplified }));//, task))
      }, 
      [workflow, showTime, showDay, showSimplified] //, task
    );

    useEffect(() => {
        if (!pusher) {
            console.log("WorkflowPanel pusher undefined");
            return;
        }
        
        const channel = pusher.subscribe("workflow");

        channel.bind_global(function (event, data) {
            console.log(`The event ${event} was triggered`);
            
            if (event == "job-changed" && data?.job_id == jobId) {
              console.log("refreshing workflow...")
              workflowQuery.refetch();
            }
        })

        return () => {
          pusher.unsubscribe("workflow")
        };
    }, [pusher]); 

    function sortTasksByName(a, b) {
      var keyA = a.name;
      var keyB = b.name;
      
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    }
    function sortTasksBySequence(a, b) {
      var keyA = new Date(a.sequence);
      var keyB = new Date(b.sequence);
      
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    }

    return (
      <>
        <Panel query={workflowQuery} checkReloading={false}>
          { workflow && 
            <>
              <div
                className="sticky left-0"
                style={{
                    width: `calc(100vw - ${dimensions.sideBarWidth*2}px - ${dimensions.cardMenuWidth}px - ${dimensions.scrollBarWidth}px - 25px)`,
                }}            
              >
                <div className="flex items-center justify-between gap-2">
                  <Field
                      prepend="Workflow:"
                      append={"Version " + workflow.data.version}
                  >
                      <div className="flex-1 self-center">
                          {workflow.data.name}
                      </div>
                  </Field>

                  <div className="flex items-center gap-2">
                    <Switch
                      label="Simplified"
                      checked={showSimplified}
                      onChange={(e, value) => setShowSimplified(value)}
                    />                    
                    <Switch
                      label="Production Time"
                      checked={showTime}
                      onChange={(e, value) => setShowTime(value)}
                    />
                    <Switch
                      label="Production Day"
                      checked={showDay}
                      onChange={(e, value) => setShowDay(value)}
                    />
                  </div>
                </div>                

                {workflow.data.workflow_dirty_ind == 1 && 
                  <Alert 
                    size="sm" 
                    variant="warning" 
                    icon={faExclamationCircle}
                    button={
                      <ButtonMutate
                        size="sm"
                        icon={faRedo}
                        caption="ReSync Workflow"
                        variant="solid-secondary"
                        mutation={resyncWorkflow}
                      />
                    }
                  >
                    There have been changes to this job that could impact the workflow.  It is recommended to ReSync it.
                  </Alert>
                }  
              </div>

              <div 
                className={clsx(
                  overflow && "overflow-auto"
                )}
                style={{
                  width: overflow ? `calc(100vw-${dimensions.sideBarWidth-dimensions.cardMenuWidth}px)` : ""
                }}
              >
                <div 
                  ref={containerRef} 
                  style={{
                    position: "relative",
                    minWidth: "3000px", 
                  }}
                  onClick={(e) => {
                    if (e.target.tagName == "svg") {
                      setTaskMenu(null);
                    }
                  }}
                >
                  {(diagram && diagram.length > 0) &&
                      <Mermaid chart={diagram} />
                  }

                  {(taskMenu && taskMenu.node) &&
                    <div 
                      className="absolute border border-gray-300 rounded bg-white z-50"
                      style={{
                        left: taskMenu.node.getBoundingClientRect().left - containerRef.current.offsetLeft + (document.getElementById("card-layout-panel")?.scrollLeft||0) + (document.getElementById("page-layout")?.scrollLeft||0),
                        top: taskMenu.node.getBoundingClientRect().top - containerRef.current.offsetTop + (document.getElementById("card-layout-panel")?.scrollTop||0) + (document.getElementById("page-layout")?.scrollTop||0) + 20
                      }}
                    >
                      {(taskMenu.task.status != "completed") && 
                        <button 
                            className={clsx(
                                "border-b border-gray-300 p-2 w-full",
                                "hover:bg-gray-100 ",
                                "disabled:bg-gray-100 disabled:text-gray-400",
                                "flex items-center gap-1 ",
                                "whitespace-nowrap ",
                                "text-sm ",
                                "cursor-pointer",
                            )}
                            onClick={(e) => {
                                e.stopPropagation();
                                
                                updateWorkflowTaskStatus.mutate(TaskStatuses.completed.id, {
                                  onSuccess: () => {
                                    setTaskMenu(null);
                                  }
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={updateWorkflowTaskStatus.isLoading ? faSpinner : faCheckCircle} fixedWidth busy={updateWorkflowTaskStatus.isLoading} />
                            Complete Task
                        </button>                      
                      }
                      <button 
                          className={clsx(
                              "border-b border-gray-300 p-2 w-full",
                              "hover:bg-gray-100 ",
                              "disabled:bg-gray-100 disabled:text-gray-400",
                              "flex items-center gap-1 ",
                              "whitespace-nowrap ",
                              "text-sm ",
                              "cursor-pointer",
                          )}
                          onClick={(e) => {
                              e.stopPropagation();
                              
                              setTask(taskMenu.task);
                              setTaskMenu(null);
                          }}
                      >
                          <FontAwesomeIcon icon={faPencilAlt} fixedWidth />
                          Edit Task
                      </button>
                    </div>
                  }                   
                </div>
              </div>

              {showTasksList &&
                <div
                  className="sticky left-0"
                  style={{
                      width: `calc(100vw - ${dimensions.sideBarWidth*2}px - ${dimensions.cardMenuWidth}px - ${dimensions.scrollBarWidth}px - 25px)`,
                  }}            
                >
                  {showDay ?
                    <Accordion>
                      {workflow.tasks.map(t => t.actual_day||t.target_day).filter((value, index, self) => self.indexOf(value) === index).map((day,index) => {
                        const tasks = workflow.tasks.filter(t => t.check_ind==0 && t.status_id != TaskStatuses.skipped.id && t.status_id != TaskStatuses.invalid.id && t.production_day == day);

                        return (
                          <AccordionItem key={day}
                            title={`Day ${day} - ${format(parseISO(tasks[0].production_date), "EEE MMM do")}`}
                            count={tasks.length}
                          >
                            <Accordion>
                              {tasks.sort(sortTasksBySequence).map(task => (
                                <TaskItem 
                                  jobId={jobId}
                                  task={task}
                                  onTask={() => setTask(task)}
                                  showTime={showTime}
                                />
                              ))}
                            </Accordion>
                          </AccordionItem>
                        )
                      })}
                    </Accordion>
                  :
                    <List>
                      {workflow.tasks.filter(t => t.check_ind==0).sort(sortTasksByName).map((task,index) => (
                        <TaskItem 
                          key={index}
                          jobId={jobId}
                          task={task}
                          onTask={() => setTask(task)}
                          showTime={showTime}
                        />
                      ))}
                    </List>
                  }
                </div>
              }
            </>     
          }    
        </Panel>

        {task && 
          <JobWorkflowTaskDrawer
              jobId={jobId}
              jobWorkflowId={task.job_workflow_id}
              onHide={() => setTask(null)}
          />
        }        
      </>
    );
}
