export default {
    API_URL: 'https://admin.signmonkey.com/api/',
    ADMIN_URL: 'https://admin.signmonkey.com.com/',
    SITE_URL: 'https://signmonkey.com/',
    SITE_API_URL: 'https://signmonkey.com/api/',
    SITE_SSE_URL: 'https://signmonkey.com/sse/',
    SIGN_URL: 'https://signmonkey.com/api/builder/signdata?id=',
    SIGNEDIT_URL: 'https://signmonkey.com/build?uid=',
    SIGNDETAILS_URL: 'https://signmonkey.com/sign?uid=',
    ORDERDETAILS_URL: 'https://signmonkey.com/account/order?uid=',
    PRICE_URL: 'https://signmonkey.com/api/signs/price',
    BUILDER_BASE_URL: 'https://signmonkey.com/builder-24-09-2024-11/',
    BUILDER_ADMIN_URL: 'https://signmonkey.com/builder-24-09-2024-11/admin/',
    BUILDER_ADMIN_INTERACTIVE_URL: 'https://signmonkey.com/builder-24-09-2024-11/admin/?interactive=1',
    BUILDER_INTERACTIVE_URL: 'https://signmonkey.com/builder-24-09-2024-11/interactive/',
    SOCKET_URL: 'https://signmonkeysockets.ue.r.appspot.com/',

    WORK_START_HOUR: 8,
    WORK_START_MINS: 30,
    WORK_END_HOUR: 17,
    WORK_END_MINS: 0
}