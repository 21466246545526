import React from "react";
import clsx from "clsx";
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'

import DrawerTemplateData from "./template-data";

import { useGetJobWorkflowTasks } from "../api/queries/workflowjobs";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import TimeBadge from "../components/badge-time";
import { formatDateTime } from "../helpers/utility";
import { useGetProductionEmployees } from "../api/queries/users";
import Loading from "../components/loading";

export default function JobWorkflowTaskDrawer({
  filters, 
  skill,
  onTask,
  onHide
}) {
  const tasksQuery = useGetJobWorkflowTasks({...filters, groupby: "job"});
  const jobs = tasksQuery.data;

  const employeesQuery = useGetProductionEmployees(skill?.role_id, null, { enabled: skill?true:false});
  const employees = employeesQuery.data;

  return (
    <DrawerTemplateData
      icon={faClipboardList}
      title={"Job Workflow Tasks"}
      drawerClassName="max-w-[500px]"
      query={tasksQuery}
      onHide={onHide}
    >
        {skill &&
            <div className="grid grid-cols-[150px,1fr] gap-2 border rounded p-1 bg-purple-200 mb-3">
                <div className="">
                    {skill.name}
                </div>
                <div>
                    {employeesQuery.isLoading ?
                        <Loading size="sm" color="white" />
                    : employeesQuery.isError ?
                        <div className="text-red-500">Error loading employees</div>
                    :
                        <div className="text-sm flex gap-1 flex-wrap">
                            {employees.map(emp => (
                                <div className="rounded bg-white p-1">
                                    {emp.name}
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        }
        <div className="space-y-2">
        {jobs && jobs.map(job => (
            <div key={job.id} className="grid grid-cols-[150px,1fr] gap-2 border rounded p-1 text-sm">
                <div className="space-y-1">
                    <div className="font-bold">
                        <a href={"/#/workflowjob/" + job.id} target="_blank" className="text-blue-500 hover:underline">
                            {job.title}
                        </a>
                    </div>
                    <ZoomableImage
                        url={storage.root + job.preview_url}
                        height={30}
                        maxHeight="40px"
                        maxWidth="145px"
                    />
                </div>
                <div>                
                    <div className="space-y-1">
                    {job.tasks.map(task => (
                        <div 
                            key={task.job_workflow_id} 
                            className={clsx(
                                "status cursor-pointer space-y-1", 
                                "hover:scale-[1.025]",
                                "status-" + task.status
                            )}
                            onClick={() => onTask ? onTask(job, task) : null}
                        >
                            <div className="flex items-center justify-between">
                                {task.name}
                                <TimeBadge minutes={task.production_actual || task.production_estimate} />
                            </div>
                            {task.completed_date ?
                                <div className="text-xs text-gray-500 bg-green-200 p-1 rounded">
                                    Completed by {task.completed_user || "?"} on {formatDateTime(task.completed_date)}
                                </div>
                            : task.status == "inprogress" ? 
                                <div className="text-xs text-gray-500 bg-blue-200 p-1 rounded">
                                    Started by {task.started_user || "?"} on {formatDateTime(task.started_date)}
                                </div>
                            :
                                null
                            }
                        </div>
                    ))}  
                    </div>                  
                </div>
            </div>
        ))}
        </div>        
    </DrawerTemplateData>  
  )
}